import hostOmni from "@/config/host";
import axios from "axios";

const bearerToken = () => JSON.parse(localStorage.getItem("login"))?.token;

let webSocketProvider, connection;

const subscriptions = {
	Atendimento: {},
	NovaMensagem: {},
	MensagemStatus: {},
	ChamadaSolicitada: {},
	ChamadaIniciada: {},
	ChamadaFinalizada: {},
	ChamadaRejeitada: {},
	ChamadaInvalida: {},
	SetWebRTCIceCandidate: {},
	SetWebRTCSessionDescription: {},
	FilaLiberada: {},
	FilaParalisada: {}
};

const eventsListeners = {
	onReconnecting: {},
	onReconnected: {},
	onClose: {}
};

const instantChatActive = [];

const connections = {};

async function connect() {
	const key = Symbol();
	connections[key] = {
		subscribe(scope, callback) {
			if (!subscriptions[scope]) throw `Escopo "${scope}" inválido.`;
			if (typeof callback != "function") throw `Parâmetro \`callback\` deve ser uma função e não \`${typeof callback}\`.`;
			subscriptions[scope][key] = callback;
		},
		unsubscribe(scope) {
			if (!subscriptions[scope]) throw `Escopo \`${scope}\` inválido.`;
			delete subscriptions[scope][key];
		},
		reconnect() {
			// Implementar instrução de reconexão manual.
			alert("Não é possível reconectar.");
		},
		disconnect() {
			Object.values(subscriptions).forEach((subscription) => delete subscription[key]);
			Object.values(eventsListeners).forEach((event) => delete event[key])
			stop(key);
		},
		liberarFilaDeAtendimento() {
			connection.send("LiberarFilaDeAtendimento");
		},
		paralisarFilaDeAtendimento() {
			connection.send("ParalisarFilaDeAtendimento");
		},
		startInstantChat(atendimentoId) {
			if (!atendimentoId) return;
			startInstantChat(atendimentoId).then(() => {
				instantChatActive.push(atendimentoId);
			});
		},
		leaveInstantChat(atendimentoId) {
			if (!atendimentoId) return;
			leaveInstantChat(atendimentoId).then(() => {
				instantChatActive.splice(instantChatActive.indexOf(atendimentoId), 1);
			});
		},
		sendMessage(message) {
			return sendMessage(message);
		},
		answerCall() {
			return answerCall();
		},
		rejectCall() {
			return rejectCall();
		},
		makeCall(atendimentoId) {
			return makeCall(atendimentoId);
		},
		hangupCall() {
			return hangupCall();
		},
		cancelCall() {
			return cancelCall();
		},
		onReconnecting(callback) {
			if (typeof callback != "function") throw `Parâmetro \`callback\` deve ser uma função e não \`${typeof callback}\`.`;
			eventsListeners.onReconnecting[key] = callback;
		},
		onReconnected(callback) {
			if (typeof callback != "function") throw `Parâmetro \`callback\` deve ser uma função e não \`${typeof callback}\`.`;
			eventsListeners.onReconnected[key] = callback;
		},
		onClose(callback) {
			if (typeof callback != "function") throw `Parâmetro \`callback\` deve ser uma função e não \`${typeof callback}\`.`;
			eventsListeners.onClose[key] = callback;
		},
		startPhone() {
			connection.send("StartPhone");
		},
		async startWebRTC(atendimentoId, enableAudioInput = false, audioOutput = null) {
			let _connection = connections[key];

			_connection.rtcConnection = new RTCPeerConnection();

			_connection.audioOutput = audioOutput ?? new Audio();
			_connection.audioOutput.autoplay = true;

			if (enableAudioInput) {
				_connection.mediaDevices = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
				_connection.mediaDevices.getTracks().forEach(track => _connection.rtcConnection.addTrack(track, _connection.mediaDevices));
			}

			_connection.rtcConnection.ontrack = event => _connection.audioOutput.srcObject = event.streams[0];
			_connection.rtcConnection.onicecandidate = event => event.candidate && connection.send("SetWebRTCIceCandidate", _connection.sipConnectionId, JSON.stringify(event.candidate));

			subscriptions.SetWebRTCIceCandidate[key] = iceCandidate => {
				let message = JSON.parse(iceCandidate);
				if (message?.candidate) _connection.rtcConnection.addIceCandidate(message);
			};
			subscriptions.SetWebRTCSessionDescription[key] = sessionDescription => {
				let message = JSON.parse(sessionDescription);
				if (message?.sdp) {
					_connection.rtcConnection.setRemoteDescription(new RTCSessionDescription(message)).then(() => {
						_connection.rtcConnection.createAnswer()
							.then(answer => _connection.rtcConnection.setLocalDescription(answer))
							.then(() => connection.send("SetWebRTCSessionDescription", _connection.sipConnectionId, JSON.stringify(_connection.rtcConnection.localDescription)));
					});
				}
			};

			connection.invoke("CreateWebRTCConnection", atendimentoId).then(sipConnectionId => _connection.sipConnectionId = sipConnectionId).then(() => {
				connection.send("StartWebRTCNegotiation", _connection.sipConnectionId);
			});
		},
		stopWebRTC() {
			let _connection = connections[key];
			if (!_connection) return;
			if (_connection.mediaDevices) _connection.mediaDevices.getTracks().forEach(track => track.stop());
			if (_connection.rtcConnection && _connection.rtcConnection.signalingState != "closed") _connection.rtcConnection.close();
			if (_connection.audioOutput) _connection.audioOutput.pause();
		}
	};

	if (!connection) {
		webSocketProvider = await axios.get("/websocket-provider").then(response => response.data);
		switch (webSocketProvider) {
			default: break;
			case "SignalR":
				connection = await import("@microsoft/signalr").then(signalR => {
					let connection = new signalR.HubConnectionBuilder()
						.withUrl(`${hostOmni}/ChatHub`, { accessTokenFactory: () => bearerToken() })
						.configureLogging(signalR.LogLevel.Error)
						.withAutomaticReconnect()
						.build();

					connection.serverTimeoutInMilliseconds = 180000;
					connection.keepAliveIntervalInMilliseconds = 60000;

					return connection;
				});

				break;
			case "SDWebSocket":
				connection = await import("@/assets/lib/sd-websocket/SDWebSocket").then(sdWebSocket => {
					return new sdWebSocket.HubConnection()
						.withUrl(`${hostOmni.replace("https://", "wss://")}/ChatHub`)
						.withAccessToken(bearerToken())
						.withAutomaticReconnect();
				});

				break;
		}
	}

	connection.on("Atendimento", atendimentoId => {
		Reflect.ownKeys(subscriptions.Atendimento).forEach(callback => {
			subscriptions.Atendimento[callback](atendimentoId);
		});
	});

	connection.on("NovaMensagem", atendimentoId => {
		Reflect.ownKeys(subscriptions.NovaMensagem).forEach(callback => {
			subscriptions.NovaMensagem[callback](atendimentoId);
		});
	});

	connection.on("MensagemStatus", (mensagemId, status, details) => {
		Reflect.ownKeys(subscriptions.MensagemStatus).forEach(callback => {
			subscriptions.MensagemStatus[callback](mensagemId, status, details);
		});
	});

	connection.on("ChamadaSolicitada", (telefone, nome) => {
		Reflect.ownKeys(subscriptions.ChamadaSolicitada).forEach(callback => {
			subscriptions.ChamadaSolicitada[callback](telefone, nome);
		});
	});

	connection.on("ChamadaFinalizada", () => {
		Reflect.ownKeys(subscriptions.ChamadaFinalizada).forEach(callback => {
			subscriptions.ChamadaFinalizada[callback]();
		});
	});

	connection.on("ChamadaIniciada", (atendimentoId, protocolo, telefone, nome) => {
		Reflect.ownKeys(subscriptions.ChamadaIniciada).forEach(callback => {
			subscriptions.ChamadaIniciada[callback](atendimentoId, protocolo, telefone, nome);
		});
	});

	connection.on("ChamadaRejeitada", () => {
		Reflect.ownKeys(subscriptions.ChamadaRejeitada).forEach(callback => {
			subscriptions.ChamadaRejeitada[callback]();
		});
	});

	connection.on("ChamadaInvalida", () => {
		Reflect.ownKeys(subscriptions.ChamadaInvalida).forEach(callback => {
			subscriptions.ChamadaInvalida[callback]();
		});
	});

	connection.on("SetWebRTCIceCandidate", iceCandidate => {
		Reflect.ownKeys(subscriptions.SetWebRTCIceCandidate).forEach(callback => {
			subscriptions.SetWebRTCIceCandidate[callback](iceCandidate);
		});
	});

	connection.on("SetWebRTCSessionDescription", sessionDescription => {
		Reflect.ownKeys(subscriptions.SetWebRTCSessionDescription).forEach(callback => {
			subscriptions.SetWebRTCSessionDescription[callback](sessionDescription);
		});
	});

	connection.on("FilaLiberada", () => {
		Reflect.ownKeys(subscriptions.FilaLiberada).forEach(callback => {
			subscriptions.FilaLiberada[callback]();
		});
	});

	connection.on("FilaParalisada", () => {
		Reflect.ownKeys(subscriptions.FilaParalisada).forEach(callback => {
			subscriptions.FilaParalisada[callback]();
		});
	});

	if (typeof connection.onreconnecting == "function") {
		connection.onreconnecting(() => {
			Reflect.ownKeys(eventsListeners.onReconnecting).forEach(key => {
				if (typeof eventsListeners.onReconnecting[key] == "function") eventsListeners.onReconnecting[key]();
			});
		});
	}

	connection.onreconnected(() => {
		Reflect.ownKeys(eventsListeners.onReconnected).forEach(key => {
			if (typeof eventsListeners.onReconnected[key] == "function") eventsListeners.onReconnected[key]();
		});
		instantChatActive.forEach(atendimentoId => {
			startInstantChat(atendimentoId);
		});
	});

	connection.onclose(() => {
		Reflect.ownKeys(eventsListeners.onClose).forEach(key => {
			if (typeof eventsListeners.onClose[key] == "function") eventsListeners.onClose[key]();
		});
	});

	await start();
	return connections[key];
}

async function start() {
	if (connection.state != "Disconnected") return Promise.resolve();
	return await connection.start();
}

const stop = connectionKey => {
	delete connections[connectionKey];
	if (Reflect.ownKeys(connections).length == 0) {
		if (connection.state != "Connected") return;
		return connection.stop();
	}
};

const startInstantChat = atendimentoId => {
	return connection.send("InstantChat", atendimentoId);
};

const leaveInstantChat = atendimentoId => {
	return connection.send("LeaveChat", atendimentoId);
};

const sendMessage = message => {
	return connection.send("SendMessage", message);
};

const answerCall = () => {
	return connection.send("AnswerCall");
};

const rejectCall = () => {
	return connection.send("RejectCall");
};

const makeCall = atendimentoId => {
	return connection.send("MakeCall", atendimentoId);
};

const hangupCall = () => {
	return connection.send("HangupCall");
};

const cancelCall = () => {
	return connection.send("CancelCall");
};

export default {
	connect
}